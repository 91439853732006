import { AdditionalPhonemeInfo, EmotionEvent, InworldConnectionService } from '@inworld/web-sdk';
import { Box } from '@mui/material';
import InworldChar3D from './InworldChar3D';

import { IconButton } from '@mui/material';

import { VolumeOff, VolumeUp } from '@mui/icons-material';

import { useState, useCallback } from 'react';

import { AvatarNone } from './AvatarNone';
import { AvatarBox } from './../../chat/Chat.styled';

export function Avatar({
  emotionEvent,
  phonemes,
  connection,
  visible,
  url,
  activeChat,
  handleActiveChat

}: {
  emotionEvent?: EmotionEvent;
  phonemes: AdditionalPhonemeInfo[];
  connection: InworldConnectionService;
  visible: boolean;
  url: string;
  activeChat: boolean;
  handleActiveChat: Function;
}) 


{

  const [isPlaybackMuted, setIsPlaybackMuted] = useState(
    connection.player.getMute() ?? false,
  );

  const handleMutePlayback = useCallback(() => {
    connection.recorder.initPlayback();
    connection.player.mute(!isPlaybackMuted);
    setIsPlaybackMuted(!isPlaybackMuted);
    connection.sendTTSPlaybackMute(!isPlaybackMuted);
  }, [connection, isPlaybackMuted]);


  const toggleActiveChat = () => {
    if(!activeChat) {
      handleActiveChat()
    }
  }

  return ( 
    
    <AvatarBox
      className={activeChat ? 'avatar active': 'avatar'}
      onClick={toggleActiveChat}
      sx={{
        boxShadow: '0px -4px 24px 0px #CA61FB inset',
        background: 'radial-gradient(50% 50% at 50% 50%, #010101 0%, #6750A4 100%)' ,
        display: visible ? 'flex' : 'none',
        transform: activeChat ? 'translateX(2px)' : '',
      }}
    > 


      <Box
      
        sx={{
          width: '100%',
          height: '100%',
          // display: activeChat ? 'flex' : 'none',
          position: 'relative',
          boxShadow: 'inset box-shadow: 0px 0px 4px 0px #EA95FF',
          // borderRadius: '180px',
          // background: 'radial-gradient(50% 50% at 50% 50%, #FFF 0%, #FFFFFF6B 100%)'
        }}
      > 
        <Box
          className='avatar-inner'
          onClick={toggleActiveChat}
          sx={{
            maxWidth: '180px',
            maxHeight: '180px',
            borderRadius: '180px',
            boxShadow: '0px 0px 4px 0px #EA95FF',
            overflow: 'hidden',
            maskImage: 'radial-gradient(white, black)', 
            willChange: 'transform', 
            zIndex: '0'
          }}
        >
          <Box
            onClick={toggleActiveChat}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              position: 'relative',
              maskImage: 'radial-gradient(white, black)', 
              willChange: 'transform', 
              zIndex: '0'
            }}
          >
            <InworldChar3D
              url={url}
              phonemes={phonemes}
              emotionEvent={emotionEvent}
            />
          </Box>

        </Box>

        { activeChat &&

          <IconButton onClick={handleMutePlayback } style={{
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              zIndex: '333',
              backgroundColor: 'rgba(232, 222, 248, 0.80)',
              backdropFilter: 'blur(4px)',
              boxShadow: '0px 16px 32px -16px rgba(0, 0, 0, 1), 0px 8px 8px -8px rgba(0, 0, 0, 0.28)'
            }}>
            {isPlaybackMuted ? (
              <VolumeOff fontSize="small" />
            ) : (
              <VolumeUp fontSize="small" />
            )}
          </IconButton>
        }
      </Box>
    </AvatarBox>
  );
}
