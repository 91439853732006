import './index.css';

import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';

import App from './App';
import theme from './app/theme';

const assistant = ReactDOM.createRoot(
  document.getElementById('assistant') as HTMLElement,
);
assistant.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
