import { styled } from '@mui/system';

const NAV_HEIGHT_XS = '3rem';
const TAB_BAR_HEIGHT = '3rem';

export const MainWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  // height: `calc(100vh -1.5rem)`,
  display: 'flex',
  flexDirection: 'column',
  // right: '40px',
  // minWidth: '160px',
  // bottom: '50px',
  [theme.breakpoints.only('xs')]: {
    // width: 'max-content',
    // minWidth: '160px',
    width: '100%',
    // bottom: '40px',
    // right: '6vw',
    // transform: 'translateX(1px)'
  },
  // [theme.breakpoints.only('xs')]: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%',
  //   paddingLeft: '0',
  //   paddingRight: '0',
  //   paddingBottom: TAB_BAR_HEIGHT,
  // },

  '.avatar-wallaper': {

    display: 'none',

    [theme.breakpoints.only('xs')]: { 
      display: 'block',
      width: '100vw',
      height: '100vh',
      top: '0',
      left: '0',
      position: 'fixed',
      backgroundColor: 'rgba(139, 137, 141, 0.65)'
    }
  },
}));
export const ChatWrapper = styled('div')(({ theme }) => ({
  // borderRadius: '8rem',
  overflow: 'hidden',
  // backgroundColor: theme.palette.background.paper,
  // height: `calc(100vh - 1.5rem)`,

  [theme.breakpoints.only('xs')]: {
    width: '100%',
    borderRadius: '0'

    // height: `calc(100vh - ${NAV_HEIGHT_XS} - ${TAB_BAR_HEIGHT})`,
    // borderRadius: 0,
  },
}));

export const SimulatorHeader = styled('div')(({ theme }) => ({
  height: '0rem',
  backgroundColor: theme.palette.background.default,
  backdropFilter: 'blur(12px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 5,
  [theme.breakpoints.only('xs')]: {
    height: 'auto',
    // padding: '0.5rem',
  },
}));
