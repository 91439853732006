import { VISEME_TYPES } from '../app/types';

export const Visemes: string[] = [
  VISEME_TYPES.SIL,
  VISEME_TYPES.PP,
  VISEME_TYPES.FF,
  VISEME_TYPES.TH,
  VISEME_TYPES.DD,
  VISEME_TYPES.KK,
  VISEME_TYPES.CH,
  VISEME_TYPES.SS,
  VISEME_TYPES.NN,
  VISEME_TYPES.RR,
  VISEME_TYPES.AA,
  VISEME_TYPES.E,
  VISEME_TYPES.I,
  VISEME_TYPES.O,
  VISEME_TYPES.U,
];
