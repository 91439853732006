import { Box, CssBaseline } from '@mui/material';
import { ReactNode } from 'react';

import { Main } from './Main';

import {
  MainBoxLayout,
} from './../chat/Chat.styled';

interface LayoutProps {
  children?: ReactNode;
}

export function Layout(props: LayoutProps) {
  return (
    <Box>
      <CssBaseline />
      <Main>
        <MainBoxLayout>{props.children}</MainBoxLayout>
      </Main>
    </Box>
  );
}
