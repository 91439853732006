import { HistoryItem, InworldConnectionService } from '@inworld/web-sdk';
import { CopyAll, Mic, Send, VolumeOff, VolumeUp, Close} from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';

import { CHAT_VIEW, EmotionsMap } from '../types';
import { ActionsStyled, RecordIcon } from './Chat.styled';
import { CopyConfirmedDialog } from './CopyConfirmedDialog';
import { History } from './History';

interface ChatProps {
  chatView: CHAT_VIEW;
  chatHistory: HistoryItem[];
  connection: InworldConnectionService;
  emotions: EmotionsMap;
  handleActiveChat: Function;
}
 
export function Chat(props: ChatProps) {
  const { chatHistory, connection, handleActiveChat} = props;
  const [text, setText] = useState('');
  const [copyDestination, setCopyDestination] = useState('');
  const [activeAir, setActiveAir] = useState(false);
  const [copyConfirmOpen, setCopyConfirmOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaybackMuted, setIsPlaybackMuted] = useState(
    connection.player.getMute() ?? false,
  );
  const [hasPlayedWorkaroundSound, setHasPlayedWorkaroundSound] =
    useState(false);

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {

      let text = e.target.value

      let input = document.querySelector('.MuiInput-input')

      if(text.trim().length) {

        setText(text);

        setActiveAir(true)
        input?.classList.add('active')
      } else {
        setText('');
        setActiveAir(false)
        input?.classList.remove('active')
      }
    },
    [],
  );

  const handleCopyClick = useCallback(async () => {
    const history = connection.getTranscript();

    if (navigator.clipboard) {
      navigator.clipboard.writeText(history).then(() => {
        setCopyDestination('clipboard');
      });
    } else {
      setCopyDestination('console');
    }

    setCopyConfirmOpen(true);
  }, [connection, chatHistory]);

  const handleMutePlayback = useCallback(() => {
    connection.recorder.initPlayback();
    connection.player.mute(!isPlaybackMuted);
    setIsPlaybackMuted(!isPlaybackMuted);
    connection.sendTTSPlaybackMute(!isPlaybackMuted);
  }, [connection, isPlaybackMuted]);

  const stopRecording = useCallback(() => {
    connection.recorder.stop();
    setIsRecording(false);
    connection.sendAudioSessionEnd();
  }, [connection]);

  const startRecording = useCallback(async () => {
    try {
      connection.sendAudioSessionStart();
      await connection.recorder.start();
      setIsRecording(true);
    } catch (e) {
      console.error(e);
    }
  }, [connection]);

  const playWorkaroundSound = useCallback(() => {
    // Workaround for browsers with restrictive auto-play policies
    connection.player.playWorkaroundSound();
    setHasPlayedWorkaroundSound(true);
  }, [connection, setHasPlayedWorkaroundSound]);



  if(window.innerWidth <= 768) {

    useEffect(() => {
      connection.recorder.initPlayback();
      connection.player.mute(false);
      setIsPlaybackMuted(false);
      connection.sendTTSPlaybackMute(false);
      (document.querySelector('audio') as HTMLMediaElement).muted = false
    }, [])
  }

  const handleSend = useCallback(() => {

    if (text) {

      let input = document.querySelector('.MuiInput-input')

      !hasPlayedWorkaroundSound && playWorkaroundSound();

      setText('');

      connection?.sendText(text).then(() => {
        
        let h_a_v = document.querySelector('.history--avatar-list')
        h_a_v?.scrollTo(0, Number(h_a_v.scrollHeight))
        input?.classList.remove('active')
        setActiveAir(false)
      });
    }
  }, [connection, hasPlayedWorkaroundSound, playWorkaroundSound, text]);

  const handleTextKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSend();
      }
    },
    [handleSend],
  );

  const handleSpeakClick = useCallback(async () => {
    !hasPlayedWorkaroundSound && playWorkaroundSound();

    if (isRecording) {
      return stopRecording();
    }

    return startRecording();
  }, [
    connection,
    hasPlayedWorkaroundSound,
    isRecording,
    playWorkaroundSound,
    startRecording,
    stopRecording,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        height: 'max-content',
        position: 'relative',
        marginTop: '.8rem',     
        zIndex: 2,
      }}
    >
      <IconButton
        onClick={() => handleActiveChat()}
        sx={{ height: '3rem', width: '3rem', position: 'absolute', top: '-3.5rem', right: '0.5rem', background: 'rgba(232, 222, 248, 0.8) !important', backdropFilter: 'blur(4px)' , boxShadow: '0px 16px 32px -16px rgba(0, 0, 0, 1), 0px 8px 8px -8px rgba(0, 0, 0, 0.28)'}}
      >
        <Close />
      </IconButton>

      <History
        history={chatHistory}
        chatView={props.chatView}
        emotions={props.emotions}
      />


      <ActionsStyled sx={{ border: '1px solid #ffffff', borderRadius: '2rem 2rem 2rem 2rem' }}>
        <TextField
          variant="standard"
          fullWidth
          value={text}
          placeholder='Write a question'
          onChange={handleTextChange}
          onKeyPress={handleTextKeyPress}
          sx={{
            backgroundColor: 'transperent',
            fontSize: '16px',
            borderRadius: '1rem',
            padding: '.6rem 0rem .6rem 0rem',
            color: '#49454F',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {activeAir &&
                  <IconButton onClick={handleSend} sx={{ position: 'absolute', top: '5px', right: '0.5rem'}}>
                    <Send sx={{ height: '1.5rem', width: '1.rem'}} />
                  </IconButton>
                }
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
        {/* <IconButton onClick={handleMutePlayback}>
          {isPlaybackMuted ? (
            <VolumeOff fontSize="small" />
          ) : (
            <VolumeUp fontSize="small" />
          )}
        </IconButton> */}
        <IconButton
          onClick={handleSpeakClick}
          sx={{ height: '3rem', width: '3rem' }}
        >
          {isRecording ? <RecordIcon /> : <Mic />}
        </IconButton>
        {/* <IconButton onClick={handleCopyClick}>
          <CopyAll fontSize="small" />
        </IconButton> */}
      </ActionsStyled>
      <CopyConfirmedDialog
        copyConfirmOpen={copyConfirmOpen}
        copyDestination={copyDestination}
        setCopyConfirmOpen={setCopyConfirmOpen}
      />
    </Box>
  );
}
